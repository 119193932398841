<template>
  <div>
    <v-row class="mt-5">
      <v-col cols="12" class="d-flex justify-end py-5">
        <v-btn color="accent" class="black--text font-weight-bold rounded-xl"
          :disabled="!user.permissions.Event || !user.permissions.Event.update" @click="openCreateAccessDialog()">
          <span>
            <v-icon> mdi-plus </v-icon>
            Nuevo acceso
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="ticketsHeaders" :items="listEventTickets.tickets" :loading="accessTableLoader"
          item-key="name" class="elevation-1" v-if="listEventTickets.tickets &&
            listEventTickets.tickets.length &&
            listEventTickets.tickets.length > 0
            " :items-per-page-options="[2, 5, 8, 10, 15]" :options="{
            page: ticketsSearchData.page,
            itemsPerPage: ticketsSearchData.perPage,
            pageStop: listEventTickets.totalPages,
            pageCount: listEventTickets.pagingCounter,
          }" disable-sort>
          <template v-for="h in ticketsHeaders" v-slot:[`header.${h.value}`]="{ header }">
            <v-tooltip v-if="h.text == 'Ocultar accesos disponibles'" bottom :key="h.text">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ h.text }}</span>
              </template>
              <span>Se refiere a ocultar de la vista del asistente la cantidad de
                lugares/tickets que quedan disponibles</span>
            </v-tooltip>
            <template v-else>
              <span :key="h.text">{{ h.text }}</span>
            </template>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index" class="text-center" :class="[
            $vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-table-row'
              : '',
          ]">
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[0].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    {{ item.name }}
                  </div>
                </td>
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[1].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    {{
            `${formatNumber(item.soldTickets)}/${formatNumber(
              item.pendingTickets
            )}/${formatNumber(item.totalQuantity)}`
          }}
                  </div>
                </td>
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[2].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    {{ formatModality(item.modality) || "N/D" }}
                  </div>
                </td>
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[3].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    {{ formattCurrency(item.price) }}
                  </div>
                </td>

                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[4].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : 'd-flex justify-center'
            ">
                    <v-switch v-model="item.status" color="accent" false-value="INACTIVE" true-value="ACTIVE" :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " @change="$_updateTicketStatus(item)">
                    </v-switch>
                  </div>
                </td>

                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[5].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    {{ formatHasIva(item.billingInformation.hasIva) }}
                  </div>
                </td>
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[6].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : 'd-flex justify-center'
            ">
                    <v-switch v-model="item.hidAccess" color="accent" :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " @change="$_updateTicketStatus(item)"></v-switch>
                  </div>
                </td>
                <td :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
            ">
                  <div class="font-weight-bold mr-2 v-data-table__mobile-row__header d-flex d-sm-none">
                    {{ ticketsHeaders[7].text }}
                  </div>
                  <div :class="$vuetify.breakpoint.xsOnly
              ? 'v-data-table__mobile-row__cell'
              : ''
            ">
                    <v-row class="d-flex justify-center">
                      <icon-button color="red" @click="openDeleteAccessDialog(item)" :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " tooltip="Eliminar acceso">
                        mdi-delete-outline
                      </icon-button>
                      <icon-button color="accent" @click="openEditAccessDialog(item)" :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " tooltip="Editar acceso">
                        mdi-pencil-outline
                      </icon-button>
                      <v-btn icon color="blue darken-1" tooltip="Asignar créditos" @click="openAssignItemsDialog(item)"
                        :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " class="step-1">
                        <v-icon>
                          <slot> mdi-ticket-confirmation-outline </slot>
                        </v-icon>
                      </v-btn>
                      <icon-button color="primary" @click="openUpdateDiplomaDialog(item)" :disabled="!user.permissions.Event ||
            !user.permissions.Event.update
            " tooltip="Agregar constancia">
                        mdi-card-text-outline
                      </icon-button>
                    </v-row>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--INICIO Dialog para crear y editar un acceso-->
    <v-dialog v-model="accessDialog" width="900" @click:outside="closeAccessDialog()">
      <v-card>
        <v-card-text class="pa-2">
          <v-container>
            <v-form ref="accessForm" v-model="validAccessForm" lazy-validation>
              <v-row justify="center">
                <v-col cols="12" class="">
                  <p class="headline font-weight-bold text-center mb-0">
                    Acceso
                    <span v-if="eventSelected.paymentModality"
                      class="subtitle-1 font-weight-bold text-center mb-0 secondary--text">evento gratuito
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" dark v-on="on" small>mdi-help-circle</v-icon>
                        </template>
                        <span>Tiene configurado el evento como gratuito. Algunos
                          campos le aparecerán como deshabilitados
                        </span>
                      </v-tooltip>
                    </span>
                  </p>
                </v-col>
                <!--<v-col v-if="eventSelected.paymentModality" cols="12" class="pt-0">
                  <p class="subtitle-1 font-weight-bold text-center mb-0 secondary--text">Evento gratuito
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on" small
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Tiene configurado el evento como gratuito. Algunos campos le aparecerán como deshabilitados
                        </span
                      >
                    </v-tooltip>
                  </p>
                </v-col>-->
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12">
                      <p class="subtitle-1 font-weight-bold text-center mb-0">
                        Información de acceso
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="access.name" label="Nombre" filled rounded :rules="nameRules">
                      </v-text-field>
                      <v-select v-model="access.modality" :items="modalityCategories" label="Modalidad" filled rounded
                        required :rules="eventSelected.eventModality == 'HYBRID'
              ? modalityRules
              : []
            " :disabled="eventSelected.eventModality != 'HYBRID'">
                        <template slot="selection" slot-scope="data">
                          <span>{{ data.item.item }}</span>
                        </template>
                        <template slot="item" slot-scope="data">
                          <span>{{ data.item.item }}</span>
                        </template>
                      </v-select>
                      <v-text-field v-model.number="access.price" label="$ Precio" filled rounded :rules="eventSelected.paymentModality
              ? []
              : access.membersOnly
                ? membersPriceRules
                : priceRules
            " :disabled="eventSelected.paymentModality">
                      </v-text-field>

                      <v-col v-if="user.role != 'ORGANIZATION'" cols="12"
                        class="text-align-center mb-3 d-flex justify-space-between">
                        <span class="font-weight-bold d-flex align-center">Precio exclusivo para miembro</span>
                        <v-switch class="d-flex align-center ma-0" color="#feb300" hide-details
                          v-model="access.membersOnly"></v-switch>
                      </v-col>
                      <v-select v-if="access.membersOnly && isImefOrganization(user.id)
            " v-model="access.membersOnlyAffiliationType" :items="computedAffiliationTypes"
                        item-text="name" item-value="id" label="Categoria" filled rounded required>
                      </v-select>
                      <v-textarea rows="3" v-model="access.details" label="Detalles (Qué incluye el boleto)" filled
                        rounded :rules="detailsRules">
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="12">
                      <p class="subtitle-1 font-weight-bold text-center mb-0">
                        Datos fiscales de acceso
                        <v-menu open-on-hover top offset-y nudge-left="200" max-width="350">
                          <template #activator="{ on, attrs }">
                            <v-icon color="black" v-on="on" v-bind="attrs" size="14">
                              fas fa-question-circle
                            </v-icon>
                          </template>
                          <v-list>
                            <v-list-item>
                              <span class="text-justify">
                                Si tiene habilitada la facturación para este
                                evento, es necesario llenar la siguiente
                                información para que sus clientes puedan generar
                                sus facturas &nbsp;
                                <button class="font-weight-bold cursor" @click="showDialog = true"
                                  style="color: #feb300">
                                  ver ejemplo...</button><br />
                                Si no tiene habilitada la facturación, puede
                                habilitarla en "Personalización" en la sección
                                de "Configuración de ingresos".
                              </span>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-dialog v-model="showDialog" width="700" @click:outside="showDialog = false">
                          <v-card class="pa-10">
                            <p class="subtitle-1 font-weight-bold text-center mb-7">
                              Datos fiscales de acceso
                            </p>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field label="Clave de unidad" filled rounded value="E48" disabled>
                                </v-text-field>
                                <v-text-field label="No de identificación" filled rounded value="SER009" disabled>
                                </v-text-field>
                                <v-text-field :items="modalityCategories" label="Nombre de unidad" filled rounded
                                  readonly value="Servicio">
                                </v-text-field>

                                <v-text-field label="Clave de producto" type="number" filled rounded value="80141607"
                                  disabled>
                                </v-text-field>

                                <v-textarea label="Descripción o concepto" filled rounded disabled
                                  value="Boleto General al congreso nacional de arquitectura">
                                </v-textarea>
                              </v-col>
                            </v-row>
                            <v-card-actions class="d-flex justify-center">
                              <action-button @click="showDialog = false">
                                Cerrar
                              </action-button>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <!-- Fin Dialog -->
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="access.billingInformation.unitKey" label="Clave de unidad" filled rounded
                        :disabled="!eventSelected.offerBilling" @blur="fetchUnitDescription">
                      </v-text-field>
                      <v-text-field v-model="access.billingInformation.sku" label="No de identificación" filled rounded
                        :disabled="!eventSelected.offerBilling">
                      </v-text-field>
                      <v-text-field v-model="access.billingInformation.unitName" :items="modalityCategories"
                        label="Nombre de unidad" filled rounded required
                        :rules="eventSelected.offerBilling ? unitNameRules : []"
                        :disabled="!eventSelected.offerBilling">
                      </v-text-field>

                      <v-text-field v-model="access.billingInformation.productKey" label="Clave de producto"
                        type="number" filled rounded :rules="eventSelected.offerBilling ? productKeyRules : []
            " :disabled="!eventSelected.offerBilling">
                        <!-- Vuetify switch -->
                      </v-text-field>

                      <v-switch v-if="!this.isEdit" v-model="access.billingInformation.hasIva"
                        label="Deseo activar la facturación exenta de IVA" color="primary"
                        @change="handleSwitchChange()" :input-value="!access.billingInformation.hasIva"></v-switch>

                      <!---->

                      Descripción o concepto

                      <v-textarea rows="2" v-model="access.billingInformation.description"
                        label="Descripción o concepto" filled rounded :rules="eventSelected.offerBilling
              ? billingDescriptionRules
              : []
            " :disabled="!eventSelected.offerBilling">
                      </v-textarea>
                      <v-dialog v-model="showConfirmationDialog" max-width="600px" v-col persistent cols="2"
                        class="pt-10 d-flex align-center justify-center">
                        <v-card>
                          <v-card-title class="title text-left" style="word-break: break-word">
                            ¿Estás seguro de que las facturas de este boleto
                            deben generarse exentas de IVA?
                          </v-card-title>

                          <v-card-actions>
                            <v-btn color="accent" class="rounded-xl" :small="$vuetify.breakpoint.xsOnly"
                              @click="confirmExemption()">
                              <span class="font-weight-bold black--text">CONFIRMAR</span>
                            </v-btn>
                            <v-btn color="white" class="rounded-xl" :small="$vuetify.breakpoint.xsOnly"
                              @click="cancelExemption()">
                              <span class="font-weight-bold black--text">CANCELAR</span>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="pb-2" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            ">
                <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" elevation="0" rounded
                  @click="closeAccessDialog()">
                  Cancelar
                </v-btn>
                <v-btn v-if="access.id == ''" class="font-weight-bold black--text mx-2" color="accent" elevation="0"
                  rounded @click="saveEventTicket()" :disabled="!validAccessForm">
                  Guardar
                </v-btn>
                <v-btn v-else class="font-weight-bold black--text mx-2" color="accent" elevation="0" rounded
                  @click="$_updateEventTicket()" :disabled="!validAccessForm">
                  Guardar
                </v-btn>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN Dialog para crear y editar un acceso-->
    <!--INICIO Confirmación de eliminación de acceso-->
    <v-dialog v-model="deleteAccessDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img class="" src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg" max-width="220" height="auto"
                contain></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p class="text-center font-weight-bold headline mb-0">
                Eliminar acceso
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="title text-center">
                ¿Esta seguro que desea eliminar el registro seleccionado?
              </p>
            </v-col>
          </v-row>

          <v-row class="d-flex pb-2 mt-5" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            ">
            <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0"
              @click="deleteAccessDialog = false">
              Cancelar
            </v-btn>
            <v-btn class="font-weight-bold black--text mx-2" color="accent" rounded elevation="0"
              @click="$_deleteEventTicket()">
              Aceptar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN Confirmación de eliminación de acceso-->
    <!--INICIO Asignación de créditos a acceso-->
    <v-dialog v-model="assignItemsDialog" max-width="700">
      <v-card>
        <v-card-text class="pa-2">
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <p class="title font-weight-black text-center px-7 pt-5">
                  Selecciona la cantidad de créditos que se asignarán a este
                  boleto
                </p>
              </v-col>
              <v-col cols="10">
                <v-slider v-model="access.itemQuantity" :max="indicators.unassignedItems"
                  :min="access.minCreditsForReturn * -1" thumb-color="secondary" thumb-label="always"></v-slider>
              </v-col>
              <v-col cols="8" class="ma-0">
                <v-sheet elevation="2" rounded="lg" class="pa-2 pb-4">
                  <v-row class="ma-0 pa-2" justify="center">
                    <span class="text-center font-weight-black wc-subtitle-2">
                      Para el evento: {{ access.name }}
                    </span>
                  </v-row>

                  <v-row class="ma-0 pa-2" justify="space-between" align="center">
                    <span class="text-center font-weight-bold">
                      Créditos que estás asignando
                    </span>
                    <br />
                    <v-col cols="4" md="3" lg="2" class="pa-0">
                      <v-text-field v-model="access.itemQuantity" class="text-center font-weight-black"
                        hide-details="auto" type="number" dense solo reverse hide-spin-buttons>
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ma-0 pa-2" justify="space-between">
                    <span class="text-center font-weight-bold">
                      Créditos que ya tienes asignados
                    </span>
                    <br />
                    <span class="text-center font-weight-black">
                      {{ access.totalQuantity }}
                    </span>
                  </v-row>

                  <v-row class="ma-0 pa-2" justify="space-between">
                    <span class="text-center font-weight-bold">
                      Total de créditos asignados
                    </span>
                    <br />
                    <span class="text-center font-weight-black">
                      {{ access.totalQuantity + access.itemQuantity }}
                    </span>
                  </v-row>

                  <v-divider></v-divider>

                  <v-row class="ma-0 pa-2 pt-4" justify="space-between">
                    <span class="text-center font-weight-bold">
                      Créditos disponibles en tu cuenta
                    </span>
                    <br />

                    <span v-if="this.access.price != 0" class="text-center font-weight-black">
                      {{ indicators.unassignedItems - access.itemQuantity }}
                    </span>
                    <span v-if="this.access.price == 0" class="text-center font-weight-black">
                      {{ indicators.unassignedItems }}
                    </span>
                  </v-row>
                  <v-row class="ma-0 pa-2 pt-4" justify="space-between">
                    <span class="text-center font-weight-bold">
                      Créditos gratuitos disponibles en tu cuenta
                    </span>
                    <br />
                    <span v-if="this.access.price == 0" class="text-center font-weight-black">
                      {{ indicators.unassignedItemsFree - access.itemQuantity }}
                    </span>
                    <span v-if="this.access.price != 0" class="text-center font-weight-black">
                      {{ indicators.unassignedItemsFree }}
                    </span>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row class="d-flex pb-2 mt-5" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
            ">
              <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0"
                @click="assignItemsDialog = false">
                Cancelar
              </v-btn>
              <v-btn class="font-weight-bold black--text mx-2" color="accent" rounded elevation="0"
                @click="$_updateAccessItems()">
                Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN Asignación de créditos a acceso-->
    <!--INICIO Dialog para asignar constancia a un acceso-->
    <v-dialog v-model="updateDiplomaDialog" width="900" persistent @click:outside="closeUpdateDiplomaDialog()">
      <!-- <v-card>
        <v-card-text>
          <v-form
            ref="updateDiplomaForm"
            v-model="validUpdateDiplomaForm"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p class="title font-weight-bold text-center px-7 pt-5">
                    Asigna una constancia para este acceso
                  </p>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <p class="text-justify subtitle-2 primary--text">
                    Carga tu plantilla en formato de imagen JPG con dimensiones
                    de 3301x2550 pixeles para orientación horizontal y de
                    2550x3301 pixeles para orientación vertical. Recuerda dejar
                    espacio para ubicar el nombre de tu asistente.
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="diplomaConfiguration.diplomaTemplate"
                    accept="image/jpeg"
                    placeholder="Selecciona un archivo"
                    prepend-inner-icon="mdi-image-area"
                    prepend-icon=""
                    filled
                    rounded
                    :rules="diplomaFileRules"
                    @change="changePicture()"
                    show-size
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" dark v-on="on"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <span
                          >Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Laudantium distinctio quia neque, voluptatibus,
                          pariatur eum velit perspiciatis laboriosam veritatis,
                          exercitationem reprehenderi.</span
                        >
                      </v-tooltip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="diplomaConfiguration.orientation"
                    label="Orientación"
                    filled
                    rounded
                    :items="[
                      { text: 'Vertical', value: 'vertical' },
                      { text: 'Horizontal', value: 'horizontal' },
                    ]"
                    @change="changePicture()"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" dark v-on="on"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <span
                          >Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Laudantium distinctio quia neque, voluptatibus,
                          pariatur eum velit perspiciatis laboriosam veritatis,
                          exercitationem reprehenderi.</span
                        >
                      </v-tooltip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.number="diplomaConfiguration.marginTop"
                    label="Ajustar altura del nombre de asistente MÁXIMO 700"
                    filled
                    rounded
                    type="number"
                    step="20"
                    min="20"
                    max="700"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" dark v-on="on"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <span
                          >Selecciona la altura que tendrá el nombre que tendrá
                          el nombre del asistente al que le llegará la
                          constancia.</span
                        >
                        <br />
                        <span>El valor máximo que puedes poner es de 700</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="diplomaConfiguration.textColor"
                    class="mt-0"
                    color="black"
                    false-value="white"
                    true-value="black"
                    :label="`Color de nombre de asistente ${
                      diplomaConfiguration.textColor == 'black'
                        ? '(Negro)'
                        : '(Blanco)'
                    }`"
                  >
                    <template v-slot:label>
                      {{
                        `Color de nombre de asistente ${
                          diplomaConfiguration.textColor == "black"
                            ? "(Negro)"
                            : "(Blanco)"
                        }`
                      }}
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" class="ml-2" dark v-on="on"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <span
                          >Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Laudantium distinctio quia neque, voluptatibus,
                          pariatur eum velit perspiciatis laboriosam veritatis,
                          exercitationem reprehenderi.</span
                        >
                      </v-tooltip>
                    </template>
                  </v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                  <v-img
                    :max-width="
                      diplomaConfiguration.orientation == 'horizontal'
                        ? '462'
                        : '357'
                    "
                    :height="
                      diplomaConfiguration.orientation == 'horizontal'
                        ? '357'
                        : '462'
                    "
                    :src="diplomaConfiguration.objectURL"
                    class="rounded-lg"
                    lazy-src="https://picsum.photos/id/11/10/6"
                  >
                    <div
                      v-if="diplomaConfiguration.orientation == 'horizontal'"
                      :style="`{position: relative; text-align: center; color: ${diplomaConfiguration.textColor}; font-weight: bold;}`"
                    >
                      <div
                        :style="`position: absolute; top: ${
                          diplomaConfiguration.marginTop / 2.05
                        }px; left: 50%; transform: translate(-50%, -50%);`"
                      >
                        Diego Manuel Ortiz Arenas
                      </div>
                    </div>

                    <div
                      v-else
                      :style="`{position: relative; text-align: center; color: ${diplomaConfiguration.textColor}; font-weight: bold;}`"
                    >
                      <div
                        :style="`position: absolute; top: ${
                          diplomaConfiguration.marginTop / 2.05
                        }px; left: 40%; transform: translate(-30%, -50%);`"
                      >
                        Diego Manuel Ortiz Arenas
                      </div>
                    </div>
                  </v-img>
                </v-col>
              </v-row>
              <v-row
                class="d-flex pb-2 mt-5"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
                "
              >
                <v-btn
                  class="font-weight-bold black--text mx-2"
                  color="grey lighten-3"
                  rounded
                  elevation="0"
                  @click="closeUpdateDiplomaDialog()"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  class="font-weight-bold black--text mx-2"
                  color="accent"
                  rounded
                  elevation="0"
                  :disabled="
                    diplomaConfiguration.objectURL ==
                      'https://via.placeholder.com/300x300.png?text=W' ||
                    diplomaConfiguration.marginTop == '' ||
                    !validUpdateDiplomaForm
                  "
                  @click="$_updateDiplomaConfiguration()"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card> -->

      <constancy-uploader :marginTop="diplomaConfiguration.marginTop" :marginLeft="diplomaConfiguration.marginLeft"
        :marginTopMemberNumber="diplomaConfiguration.marginTopMemberNumber"
        :marginLeftMemberNumber="diplomaConfiguration.marginLeftMemberNumber"
        :marginTopLocalGroup="diplomaConfiguration.marginTopLocalGroup"
        :marginLeftLocalGroup="diplomaConfiguration.marginLeftLocalGroup"
        :nameDelimeter="diplomaConfiguration.nameDelimeter" :orientation="diplomaConfiguration.orientation"
        :textColor="diplomaConfiguration.textColor" :objectURL="diplomaConfiguration.objectURL"
        :diplomaTemplate="diplomaConfiguration.diplomaTemplate" @setMarginTopMemberNumber="setMarginTopMemberNumber"
        @setMarginLeftMemberNumber="setMarginLeftMemberNumber" @setMarginTopLocalGroup="setMarginTopLocalGroup"
        @setMarginLeftLocalGroup="setMarginLeftLocalGroup" @setMarginTop="setMarginTop" @setMarginLeft="setMarginLeft"
        @setNameDelimeter="setNameDelimeter" @setOrientation="setOrientation" @setTextColor="setTextColor"
        @setDiplomaTemplate="setDiplomaTemplate" @setObjectURL="setObjectURL"
        @closeConstancyDialog="closeUpdateDiplomaDialog" @saveConstancy="$_updateDiplomaConfiguration">
        <template #title>
          <p class="title font-weight-bold text-center px-7 pt-5">
            Asigna una constancia para este acceso
          </p>
        </template>
      </constancy-uploader>
    </v-dialog>
    <!--INICIO Dialog para asignar constancia a un acceso-->
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ConstancyUploader from "@/components/dashboard/affiliation/Access/ConstancyUploader";
import ActionButton from "@/components/shared/ActionButton";
import IconButton from "@/components/shared/IconButton";
import ModalTemplate from "@/components/shared/ModalTemplate";
import { validations } from "@/share/validations";
import axios from "axios";
import { isImefOrganization } from "../../../../../share/imef"

export default {
  components: {
    ModalTemplate,
    ConstancyUploader,
    ActionButton,
    IconButton,
  },
  computed: {
    ...mapState("ticket", ["eventTicketsData", "listEventTickets"]),
    ...mapState("event", ["eventSelected"]),
    ...mapState("user", ["indicators", "user"]),
    computedAffiliationTypes() {
      //en la primera posición se agrega un objeto vacío para que el usuario pueda seleccionar la opción de no asignar una categoría
      return [
        { id: null, name: "Todas las categorías" },
        ...this.affiliationTypes,
      ];
    },
  },
  props: ["eventId"],
  data() {
    return {
      affiliationTypes: [],
      isEdit: false,
      isCreate: false,

      showConfirmationDialog: false,
      showAlert: true,
      switchValue: false,

      tutorialStep: 0,
      ticketsSearchData: {
        eventId: "",
        page: 1,
        perPage: 50,
      },
      showDialog: false,
      tabTicket: "information",
      validAccessForm: false,
      validUpdateDiplomaForm: false,
      validInformationForm: false,
      // Dialogs
      accessDialog: false,
      deleteAccessDialog: false,
      assignItemsDialog: false,
      updateDiplomaDialog: false,
      accessTableLoader: true,
      newAccessTab: "",
      ticketsHeaders: [
        {
          text: "Nombre",
          align: "center",
          value: "name",
        },
        {
          text: "Vendidos/Pendientes/Disponibles",
          align: "center",
          value: "available",
        },
        {
          text: "Modalidad",
          align: "center",
          value: "modality",
        },
        {
          text: "Costo",
          align: "center",
          value: "price",
        },
        {
          text: "Boleto activo",
          align: "center",
          value: "status",
        },

        {
          text: "IVA en Facturas",
          align: "center",
          value: "hasIVA",
        },
        {
          text: "Ocultar accesos disponibles",
          align: "center",
          value: "hidAccess",
        },

        {
          text: "Acciones",
          align: "center",
          value: "actions",
        },
      ],
      modalityCategories: [
        {
          item: "Presencial",
          value: "PRESENTIAL",
        },
        {
          item: "En línea",
          value: "VIRTUAL",
        },
        // {
        //   item: "Híbrido",
        //   value: "HYBRID",
        // },
      ],
      access: {
        id: "",
        name: "",
        price: "",
        modality: "",
        quantity: "",
        itemQuantity: "",
        inventoryType: "FINITE",
        status: "",
        details: "",
        event: "",
        totalQuantity: "",
        membersOnly: false,
        membersOnlyAffiliationType: null,
        hidAccess: false,
        minCreditsForReturn: "",
        billingInformation: {
          hasIva: false,
          unitKey: "",
          unitName: "",
          productKey: "",
          description: "",
          sku: "",
        },
      },
      accessObj: {
        id: "",
        name: "",
        price: "",
        modality: "",
        quantity: "",
        itemQuantity: "",
        membersOnly: false,
        membersOnlyAffiliationType: null,
        inventoryType: "FINITE",
        status: "",
        details: "",
        event: "",
        totalQuantity: "",
        hidAccess: false,
        billingInformation: {
          hasIva: false,
          unitKey: "",
          unitName: "",
          productKey: "",
          description: "",
          sku: "",
        },
      },
      billingInformation: {
        hasIva: false,
        unitKey: "",
        unitName: "",
        productKey: "",
        description: "",
        sku: "",
      },
      // Variables para manejo de imagenes en constancias
      diplomaConfiguration: {
        accessId: "",
        orientation: "horizontal",
        marginTopLocalGroup: 100,
        marginLeftLocalGroup: 100,
        marginTopMemberNumber: 100,
        marginLeftMemberNumber: 100,
        marginTop: 400,
        marginLeft: 220,
        nameDelimeter: 700,
        diplomaTemplate: null,
        objectURL: "https://via.placeholder.com/300x300.png?text=W",
        textColor: "black",
        event: "",
      },
      diplomaConfigurationObj: {
        accessId: "",
        orientation: "horizontal",
        marginTop: 350,
        diplomaTemplate: null,
        objectURL: "https://via.placeholder.com/300x300.png?text=W",
        textColor: "black",
        event: "",
      },
      // Configuración de diploma temporal
      diplomaConfigurationTemp: {
        accessId: "",
        orientation: "horizontal",
        marginTop: 350,
        diplomaTemplate: null,
        objectURL: "https://via.placeholder.com/300x300.png?text=W",
        textColor: "black",
        event: "",
      },
      //Validation
      nameRules: validations.generalMValidation({
        required: true,
        name: "nombre",
      }),
      priceRules: validations.numberMValidation({
        required: true,
        name: "precio",
      }),
      membersPriceRules: validations.generalMValidation({
        required: false,
        name: "precio",
      }),
      quantityRules: validations.generalFValidation({
        required: true,
        name: "cantidad",
      }),
      detailsRules: validations.generalMValidation({
        required: true,
        name: "detalle",
      }),
      modalityRules: validations.generalFValidation({
        name: "modalidad",
        required: true,
      }),
      // Reglas de formulario de información fiscal
      unitNameRules: validations.generalMValidation({
        required: true,
        name: "nombre de unidad",
      }),
      unitKeyRules: validations.generalFValidation({
        required: true,
        name: "clave de unidad",
        maxLength: 4,
      }),
      skuRules: validations.generalMValidation({
        required: true,
        name: "Número de identificación",
      }),
      productKeyRules: validations.generalFValidation({
        required: true,
        name: "clave de producto",
        minLength: 8,
        maxLength: 8,
      }),
      billingDescriptionRules: validations.generalFValidation({
        name: "descripción",
        required: true,
      }),
      diplomaFileRules: [
        (value) => {
          if (value && value.size) {
            return (
              !value ||
              value.size < 5000000 ||
              "El archivo no debe de pesar más de 5MB"
            );
          } else {
            return true;
          }
        },
      ],
    };
  },
  methods: {
    ...mapActions("ticket", [
      "fetchEventTickets",
      "fetchEventTicketsList",
      "createEventTicket",
      "updateEventTicket",
      "deleteEventTicket",
      "assignItemsToTicket",
      "unassignItemsToTicket",
      "updateDiplomaConfiguration",
    ]),
    ...mapActions("user", ["getIndicators"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    isImefOrganization,
    async getAffiliationTypes() {
      try {
        const response = await this.axios({
          method: "GET",
          url: "affiliation-types/organization",
        });

        this.affiliationTypes = response.data.affiliationTypes;
      } catch (error) {
        console.log(error);
      }
    },
    handleSwitchChange() { },
    handleSwitchChange() {
      this.switchValue = this.access.billingInformation.hasIva;
      this.showConfirmationDialog = this.switchValue;

      console.log(this.access.billingInformation.hasIva);
    },
    confirmExemption() {
      this.showConfirmationDialog = false;
      this.access.billingInformation.hasIva = true;
      this.switchValue = true;
      console.log(this.access.billingInformation.hasIva);
    },
    cancelExemption() {
      this.showConfirmationDialog = false;
      this.access.billingInformation.hasIva = false;
      this.switchValue = false;
      console.log(this.access.billingInformation.hasIva);
    },

    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    setMarginTopMemberNumber(value) {

      Object.assign(this.diplomaConfiguration, { marginTopMemberNumber: value });
    },

    setMarginLeftMemberNumber(value) {
      Object.assign(this.diplomaConfiguration, { marginLeftMemberNumber: value });
    },

    setMarginTopLocalGroup(value) {
      Object.assign(this.diplomaConfiguration, { marginTopLocalGroup: value });
    },

    setMarginLeftLocalGroup(value) {
      Object.assign(this.diplomaConfiguration, { marginLeftLocalGroup: value });
    },

    setMarginTop(value) {
      Object.assign(this.diplomaConfiguration, { marginTop: value });
    },
    setMarginLeft(value) {
      Object.assign(this.diplomaConfiguration, { marginLeft: value });
    },
    setNameDelimeter(value) {
      Object.assign(this.diplomaConfiguration, { nameDelimeter: value });
    },
    setOrientation(value) {
      Object.assign(this.diplomaConfiguration, { orientation: value });
    },
    setTextColor(value) {
      Object.assign(this.diplomaConfiguration, { textColor: value });
    },
    setDiplomaTemplate(value) {
      Object.assign(this.diplomaConfiguration, { diplomaTemplate: value });
    },
    setObjectURL(value) {
      Object.assign(this.diplomaConfiguration, { objectURL: value });
    },
    startTutorial() {
      this.tutorialStep = 1;
    },
    //

    async fetchUnitDescription() {
      try {
        const response = await axios.post("invoices/validate-unit-name", {
          unitKey: this.access.billingInformation.unitKey.toUpperCase(),
        });
        const unitNames = response.data.unitNames;

        if (unitNames.length > 0) {
          this.access.billingInformation.unitName = unitNames[0].description;
        } else {
          this.access.billingInformation.unitName = "";
        }
      } catch (error) {
        if (response.status === 400) {
          this.access.billingInformation.description =
            "Error: Clave de unidad inválida";
        } else {
          console.error(error);
        }
      }
    },

    /**
     * Función para validar que los campos del formulario de usuario hayan sido llenados de la forma correcta.
     */
    validateAccessForm() {
      if (this.$refs.accessForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    validateUpdateDiplomaForm() {
      if (this.$refs.updateDiplomaForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async saveEventTicket() {
      if (this.validateAccessForm()) {
        //Se crea el boleto
        this.loading();
        this.access.billingInformation.hasIva = !this.switchValue;

        let response = await this.createEventTicket(this.access);

        //Se cierra el dialog
        this.accessDialog = false;

        //La tabla cambia a estado de "cargando"
        this.accessTableLoader = true;

        //Se consultan los boletos disponibles
        await this.fetchEventTicketsList(this.ticketsSearchData);

        //La tabla vueve a su estado original
        this.accessTableLoader = false;
        this.closeAccessDialog();
        this.loaded();
      }
    },
    async $_updateEventTicket() {
      if (this.validateAccessForm()) {
        this.loading();

        await this.updateEventTicket(this.access);
        this.accessDialog = false;
        this.accessTableLoader = true;

        //Se consultan los boletos disponibles
        await this.fetchEventTicketsList(this.ticketsSearchData);

        //La tabla vueve a su estado original
        this.accessTableLoader = false;

        this.closeAccessDialog();
        this.loaded();
      }
    },
    async $_updateTicketStatus(access) {
      this.loading();
      this.access.id = access._id;
      this.access.name = access.name;
      this.access.price = access.price;
      this.access.modality = access.modality;
      this.access.quantity = access.quantity;
      this.access.membersOnly = access.membersOnly;
      this.access.membersOnlyAffiliationType =
        access.membersOnlyAffiliationType;
      this.access.inventoryType = access.inventoryType;
      this.access.status = access.status;
      this.access.details = access.details;
      this.access.event = access.event;
      this.access.billingInformation = access.billingInformation;
      this.access.hidAccess = access.hidAccess;
      await this.updateEventTicket(this.access);
      this.loaded();
    },
    async $_deleteEventTicket() {
      this.loading();
      await this.deleteEventTicket(this.access);
      this.deleteAccessDialog = false;
      this.accessTableLoader = true;

      //Se consultan los boletos disponibles
      await this.fetchEventTicketsList(this.ticketsSearchData);

      //La tabla vueve a su estado original
      this.accessTableLoader = false;
      this.access = JSON.parse(JSON.stringify(this.accessObj));
      this.access.event = this.$route.params.eventId;
      this.loaded();
    },
    async $_updateAccessItems() {
      this.loading();
      try {
        let response = {};
        if (this.access.itemQuantity < 0) {
          response = await this.unassignItemsToTicket(this.access);
          this.show({
            text: response.message,
            color: "error",
          });
        } else if (this.access.itemQuantity > 0) {
          response = await this.assignItemsToTicket(this.access);
        }
        if (response.status) {
          this.show({
            text: "Operación realizada con éxito",
            color: "primary",
          });
        } else {
          this.show({
            text:
              response.message && response.message.message
                ? response.message.message
                : "No se ha podido realizar la operación",
            color: "error",
          });
        }
        await this.getIndicators();
        await this.fetchEventTicketsList(this.ticketsSearchData);
        this.assignItemsDialog = false;
      } catch (error) {
        this.show({
          text: "Por favor comunicarse con soporte técnico",
          color: "error",
        });
        console.log(error);
      }
      this.loaded();
    },
    async $_updateDiplomaConfiguration() {
      this.loading();
      try {
        console.log("Datos enviados:", this.diplomaConfiguration);
        this.diplomaConfiguration.accessId = this.affiliation._id;
        this.diplomaConfiguration.event = this.affiliation.event;

        let response = await this.updateDiplomaConfiguration(
          this.diplomaConfiguration
        );

        //console.log("ROMP", this.diplomaConfiguration);


        if (response.status) {
          this.show({
            text: "Se han guardado los cambios correctamente",
            color: "primary",
          });
        }
        await this.fetchEventTicketsList(this.ticketsSearchData);
        this.updateDiplomaDialog = false;
      } catch (error) {
        this.show({
          text: "Ha ocurrido un problema al actualizar el registro",
          color: "error",
        });
      } finally {
        this.loaded();
      }
    },

    openCreateAccessDialog() {
      if (this.eventSelected.eventModality == "PRESENTIAL") {
        this.access.modality = "PRESENTIAL";
      } else if (this.eventSelected.eventModality == "VIRTUAL") {
        this.access.modality = "VIRTUAL";
      } else if (this.eventSelected.eventModality == "HYBRID") {
        this.access.modality = "HIBRIDO";
      }
      // Si el evento es gratis poner el precio del acceso que se quiere crear en cero
      if (this.eventSelected.paymentModality) {
        this.access.price = 0;
      }
      this.accessDialog = true;
    },
    openEditAccessDialog(access) {
      //console.log(access);
      this.isEdit = true;
      this.access.id = access._id;
      this.access.name = access.name;
      this.access.price = access.price;
      this.access.membersOnly = access.membersOnly;
      this.access.membersOnlyAffiliationType =
        access.membersOnlyAffiliationType;
      if (access.modality == "PRESENTIAL") {
        this.access.modality = "PRESENTIAL";
      } else if (access.modality == "VIRTUAL") {
        this.access.modality = "VIRTUAL";
      } else if (access.modality == "HYBRID") {
        this.access.modality = "HIBRIDO";
      }
      if (access.billingInformation) {
        this.access.billingInformation = access.billingInformation;
      }
      //this.access.modality = access.modality;
      this.access.quantity = access.quantity;
      this.access.inventoryType = access.inventoryType;
      this.access.status = access.status;
      this.access.details = access.details;
      this.access.event = access.event;
      this.accessDialog = true;
    },
    openDeleteAccessDialog(access) {
      console.log("Datos selecionados para eliminar el acceso");
      console.log(access);
      this.access.id = access._id;
      this.access.event = access.event;
      this.deleteAccessDialog = true;
    },
    openAssignItemsDialog(access) {
      this.access.minCreditsForReturn =
        access.totalQuantity - access.soldTickets;
      this.access.price = access.price;
      this.access.id = access._id;
      this.access.name = access.name;
      this.access.totalQuantity = access.totalQuantity;
      // Poner en cero el contador del slider cada que se abra el dialog
      this.access.itemQuantity = 0;
      this.access.event = access.event;
      //this.access.event = this.$route.params.id;
      this.assignItemsDialog = true;
      console.log(this.access);
    },
    openUpdateDiplomaDialog(access) {
      this.affiliation = access;
      let {
        marginTop = 400,
        marginLeft = 220,
        nameDelimeter = 700,
        diplomaTemplate,
        textColor,
        orientation,
        marginTopMemberNumber = 100,
        marginLeftMemberNumber = 50,
        marginTopLocalGroup = 150,
        marginLeftLocalGroup = 50,
      } = this.affiliation.diplomaConfiguration ?? this.diplomaConfiguration;
      let { top, left } = {
        top: orientation == "horizontal" ? 2.34 : 2.56,
        left: orientation == "horizontal" ? 2.58 : 2.35,
      };

      this.setMarginTop(Math.round((marginTop + 40) / top));
      this.setMarginLeft(Math.round(parseInt(marginLeft) / left));
      this.setNameDelimeter(Math.round(parseInt(nameDelimeter) / left));


      this.setMarginTopMemberNumber(Math.round(parseInt(marginTopMemberNumber + 15) / top));
      this.setMarginLeftMemberNumber(Math.round(parseInt(marginLeftMemberNumber) / left));
      this.setMarginTopLocalGroup(Math.round(parseInt(marginTopLocalGroup + 15) / top));
      this.setMarginLeftLocalGroup(Math.round(parseInt(marginLeftLocalGroup) / left));


      this.setObjectURL(diplomaTemplate);
      this.setTextColor(textColor);
      this.setOrientation(orientation);
      this.updateDiplomaDialog = true;
    },

    closeAccessDialog() {
      this.isEdit = false;
      this.access = JSON.parse(JSON.stringify(this.accessObj));
      this.access.event = this.$route.params.eventId;
      this.accessDialog = false;
    },
    closeUpdateDiplomaDialog(close = false) {
      console.log(this.diplomaConfiguration);
      if (!close) {
        this.updateDiplomaDialog = false;
      }
      this.diplomaConfiguration = {
        accessId: "",
        orientation: "horizontal",
        marginTop: 400,
        marginLeft: 220,
        nameDelimeter: 700,
        diplomaTemplate: null,
        objectURL: "https://via.placeholder.com/300x300.png?text=W",
        textColor: "black",
        event: "",
        marginTopMemberNumber: 100,
        marginLeftMemberNumber: 50,
        marginTopLocalGroup: 150,
        marginLeftLocalGroup: 50,
      };
    },
    /**
     * Esta función se ejecuta cuando se cambia la imagen en de la constancia
     */
    formatModality(modality) {
      const moldalities = {
        PRESENTIAL: "Presencial",
        VIRTUAL: "Virtual",
        HYBRID: "Mixto",
      };

      return moldalities[modality];
    },

    formatHasIva(hasIva) {
      const doesItHasIva = {
        true: "Normal 16%",
        false: "Exento de IVA",
      };

      return doesItHasIva[hasIva];
    },

    formattCurrency(rawNumber) {
      let formatter = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
      });
      return formatter.format(rawNumber);
    },
  },
  async mounted() {
    this.ticketsSearchData.eventId = this.eventId;
    await this.fetchEventTicketsList(this.ticketsSearchData);

    await this.getAffiliationTypes();
    this.accessTableLoader = false;
    this.access.event = this.eventId;

    console.log(this.listEventTickets);
    if (/*this.$route.params.showTutorial == true*/ true) {
      this.$nextTick(() => {
        const tour = this.$shepherd({
          useModalOverlay: true,
        });

        tour.addStep({
          id: "step-1",
          text: "Debes asignar creditos para empezar a vender",
          attachTo: {
            element: ".step-1",
            on: "bottom",
          },
          buttons: [
            {
              text: "Siguiente",
              action: tour.next,
            },
          ],
        });
        tour.addStep({
          id: "step-2",
          text: "Luego de asignar los creditos ya estás listo para vender, ve a mis eventos y comparte la liga",
          arrow: false,
          attachTo: {
            element: ".step-2",
            on: "right",
          },
          buttons: [
            {
              text: "Siguiente",
              action: tour.next,
            },
          ],
        });

        //tour.start();
      });
    }
  },
};
</script>

<style></style>